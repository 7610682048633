<template>
  <div class="page">
    <h2>Rehearsal Dinner - FAQ</h2>

    <section>
      <div class="faq">
        <p class="question">
          What is the rehearsal dinner? Why am I invited?
        </p>
        <p class="answer">
          The rehearsal dinner is just our welcome event for everyone for the wedding weekend.
          The wedding day will go by so quickly, we want a chance to see all of our family and
          out of town guests the day before! It’s a casual party in our apartment’s event space
          with pizza, snacks, and drinks.
        </p>
      </div>

      <div class="faq">
        <p class="question">
          How do I get to the rehearsal dinner?
        </p>
        <p class="sub-section">
          Directions to Rehearsal Dinner from Hotel
        </p>
        <p class="answer">
          The U District Station of the Link Light Rail is a 4 minute walk from the hotel.
          Take the train to Westlake Station (13 minutes on the train). Our apartment building
          is a 13 minute walk from the train station along 4th Avenue.
        </p>
        <p class="sub-section">
          Parking Directions for Rehearsal Dinner
        </p>
        <p class="answer">
          <strong>Street Parking</strong>: There is a lot of paid street parking available along
          4th avenue between Battery and Cedar. You can pay for parking with the
          PaybyPhone Parking App. Parking is free after 8 pm.
        </p>
        <p class="answer">
          <strong>Parking Lots</strong>: The closest lot is the Windermere Parking Diamond Parking
          lot. It is a block and a half away from the apartment building entrance and charges
          $5/hour for parking.
        </p>
        <p class="sub-section">
          Directions to event space
        </p>
        <p class="answer">
          At the front entrance, enter code in the front door. We will send the code to
          your email on the day before the event.
        </p>
        <p class="answer">
          If you have any trouble getting in, text Megan (Maid of Honor) at 360-584-6952 and
          she can open the door for you remotely.
        </p>
        <p class="answer">
          Take the  elevator up to floor 2. The event space is right next to the elevator
          bay on the 2nd floor.
        </p>
      </div>

      <div class="faq">
        <p class="question">
          What do I wear to the rehearsal dinner?
        </p>
        <p class="answer">
          The rehearsal dinner is a casual event. If you have little ones or
          are interested in swimming while you’re there, bring a swimsuit.
        </p>
      </div>

      <div class="faq">
        <p class="question">
          Can I bring anything?
        </p>
        <p class="answer">
          Sure! We will be providing pizza, snacks, and drinks, but feel free to
          bring food or drink to share if you wish.
        </p>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'RehearsalDinnerFaqPage',
};
</script>

<style lang="scss" scoped>
.page {
  padding-bottom: 15rem;

  .faq {
    margin-bottom: 3rem;

    p {
      margin-bottom: 1rem;

      &.question {
        font-family: 'Raleway', sans-serif;
        font-size: 2rem;
        font-weight: bold;
        margin-bottom: 1rem;
      }

      &.sub-section {
        font-family: 'Raleway', sans-serif;
        font-size: 1.75rem;
        font-weight: bold;
        margin-bottom: 1rem;
      }
    }
  }
}
</style>
