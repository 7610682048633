<template>
  <div class="page">
    <h2>FAQ</h2>

    <section>
      <p class="center-container">
        <router-link
          class="button button-secondary"
          to="rehearsal-faq"
        >
          Rehearsal Dinner FAQ
        </router-link>
      </p>
    </section>

    <section class="faq-section">
      <div class="faq">
        <p class="question">
          When should I get there?
        </p>
        <p class="answer">
          Please arrive early. Parking will take some time.  We recommend getting to campus
          at 4:00 pm. The ceremony space will be open at 4 pm to all guests. We will start
          the ceremony promptly at 4:30 pm.
        </p>
      </div>

      <div class="faq">
        <p class="question">
          What’s the dress code?
        </p>
        <p class="answer">
          “Dress code” is a strong word, but let’s say cocktail.
        </p>
      </div>

      <div class="faq">
        <p class="question">
          How do I park?
        </p>
        <p class="answer">
          Parking on the UW campus is free on Saturdays after 12 pm. The closest parking lots
          to the building are the Padelford Parking Garage and the Central Plaza Parking Garage
          under Red Square. Accessible parking is available outside the North entrance to the HUB.
        </p>
        <p class="answer">
          If you plan on going to the after party at Shultzy’s, we recommend parking in the
          Central Plaza Parking Garage under Red Square. It is a short walk from the venue
          and an even shorter walk from the after party.
        </p>
        <p class="answer">
          If you don’t plan on attending the after party, the Padelford Parking Garage is
          the closest general parking lot to the venue.
        </p>
      </div>

      <div class="faq">
        <p class="question">
          How to get to the HUB from the hotel?
        </p>
        <p class="answer">
          <strong>Walking</strong> - The Husky Union Building is about a 15-20 minute walk
          from the hotel through campus. The fastest path would be to enter into campus
          north of the law school and walk across the quad towards the Husky Union Building.
        </p>
        <p class="answer">
          <strong>Driving</strong> - The Husky Union Building is about a 5-10 minute
          drive from the hotel.
        </p>
      </div>

      <div class="faq">
        <p class="question">
          How are we getting to the after party?
        </p>
        <p class="answer">
          This is Alex and Lindsey we’re talking about. Obviously we’re walking! Shultzy’s
          is about a 10 minute walk from the venue.
        </p>
        <p class="answer">
          You can also drive over and meet us there. There is free street parking on the Ave
          (University Way) after 8 pm. If you’re parked in the Central Plaza Parking Garage,
          we recommend leaving your car there since it’s a short walk from the after party location.
        </p>
      </div>

      <div class="faq">
        <p class="question">
          Where is the ceremony and reception?
        </p>
        <p class="answer">
          The ceremony is in the HUB Lyceum, located at the south end of the HUB on
          the first floor (room 160).
        </p>
        <p class="answer">
          The reception will be in the HUB South Ballroom, located on the second floor (room 211).
        </p>
      </div>

      <div class="faq">
        <p class="question">
          Do I need a car for the weekend?
        </p>
        <p class="answer">
          If you’re comfortable with walking up to ¾ of a mile, and you are staying with
          everyone at the Residence Inn, you can get away with not having a car for the
          weekend. The new U District train station is 2 blocks from the hotel. You can
          use the train to get from the airport to the hotel, to tourist attractions like
          Pike Place Market downtown, and to the rehearsal dinner. The train does not get
          you significantly closer to the venue, but it is a nice, ¾ mile walk through campus.
          If you need public transport from the hotel to the venue, the 75 and 372 buses
          departs near the hotel and stop outside the Husky Union Building at Stevens Way
          and Benton Lane. If you’re taking the bus to the venue, we recommend giving yourself
          some extra time in case of delays.
        </p>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'FaqPage',
};
</script>

<style lang="scss" scoped>
.page {
  padding-bottom: 15rem;

  h2 {
    margin-bottom: 3rem;
  }

  .faq-section {
    margin-top: 3rem;
  }

  .faq {
    margin-bottom: 3rem;

    p {
      margin-bottom: 1rem;

      &.question {
        font-family: 'Raleway', sans-serif;
        font-size: 2rem;
        font-weight: bold;
        margin-bottom: 1rem;
      }
    }
  }
}
</style>
